<template>
  <div id="agencies-form-create">
    <b-form>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Nombre cliente" label-for="name">
            <b-form-input id="name"
              placeholder="Ingrese el nombre"
              v-model="createForm.name"
              name="name"
              :state="nameErrors.length > 0 ? false:null"
              @input="$v.createForm.name.$touch()"
              />
            <small class="text-danger">{{ nameErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group label="Proyecto" label-for="project">
            <v-select
              v-model="createForm.project"
              name="project"
              @input="$v.createForm.project.$touch()"
              :state="projectErrors.length > 0 ? false:null"
              label="name"
              placeholder="Seleccione el proyecto"
              :options="projects"
              :reduce="projects => projects.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ projectErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col xl="12" sm="12">
          <b-form-group label="Foto cliente" label-for="img-contacto">
          <b-form-file
              id="logo"
              type="file"
              browse-text="Formatos: jpeg - jpg - svg" 
              accept=".jpg, .svg, .jpeg"
              @change="_previewLogo"
              placeholder="Selecciona una imagen" />
              <small class="text-danger">{{ logoErrors[0] }}</small>
          </b-form-group>
          <b-row class="mt-2" v-if="createForm.media_logo != undefined">
            <b-col v-if="createForm.media_logo.full_url === ''" cols="12">
                <div class="preview">
                </div>
            </b-col>
            <b-col v-else cols="12">
                <div class="container" width="300" >
                    <img class="image" :src="createForm.media_logo.full_url" style="max-width:300px">
                </div>
            </b-col> 
          </b-row>
        </b-col>         
       
        <b-col cols="12" class="margin-bottom-20">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Comentario cliente</p>
            <ckeditor id="editor1" :editor="editor" v-model="createForm.comment" :config="editorConfig"
            :state="commentErrors.length > 0 ? false:null"
            @input="$v.createForm.comment.$touch()"
            ></ckeditor>
            <small class="text-danger">{{ commentErrors[0] }}</small>
          </div>
        </b-col>  

      </b-row> 
    </b-form>

    <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()">
                <b-button block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_createComments()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                  Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>


  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'  

  export default{
    name: 'CommentsFormCreate',
    data() {
      return {
        overlayForm: false,
        logoAgency: '',
        createForm: {
          name: '',
          comment: '',
          project_id: '',
          media_logo: {full_url:''},
          logo: ''
        },
        sheet:{
          backgroundColor:"#EFEFEF",
        },
        editor: ClassicEditor,
        editorConfig: {
          toolbar: [ 
            'heading', '|', 'bold', 'italic', '|' , "undo", "redo" , 'link', 'bulletedList', 'numberedList', 'blockQuote' ,
            'insertTable' , 'tableColumn', 'tableRow', 'mergeTableCells',
            
            ],
          height: "800px",
        },
      }
    },
    validations: {
      createForm: {
        name: {
          required,
        },
        comment: {
          required,
        },
        project:{
          required
        },
        logo:{
          required
        }
         
      },
    },
    computed: {
      ...mapGetters('profile',['projects']),
      nameErrors () {
        const errors = []
        if (!this.$v.createForm.name.$dirty) return errors
        !this.$v.createForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      commentErrors () {
        const errors = []
        if (!this.$v.createForm.comment.$dirty) return errors
        !this.$v.createForm.comment.required && errors.push('El comentario es obligatorio')
        return errors
      },
      projectErrors () {
        const errors = []
        if (!this.$v.createForm.project.$dirty) return errors
        !this.$v.createForm.project.required && errors.push('El proyecto es obligatorio')
        return errors
      },
      logoErrors() {
        const errors = []
        if (!this.$v.createForm.logo.$dirty) return errors
        !this.$v.createForm.logo.required && errors.push('La imagen del header es obligatoria')
        return errors
      },
       
    },
    methods: {
      ...mapActions('comments',['createComments']),
      async _createComments() {
        this.$v.createForm.$touch()
        if (!this.$v.createForm.$invalid) {
          this.overlayForm = true
          await this.createComments(this.createForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'noticia registrada con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.createForm =  {
              name:'',
              comment:'',
              project_id: '',
              media_logo: {full_url:''}
            }
            this.$v.createForm.$reset()
            this.$router.push({ name: 'comments'})
            }).catch(err => {
              console.log("err");
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Problemas al registrar el comentario',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
                this.overlayForm = false
            })
        }
      }, 
      _onHidden() {
        if(this.$refs.button != null){
          this.$refs.button.focus()
        }
      },
      _previewLogo(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.createForm.media_logo.full_url = URL.createObjectURL(event.target.files[0])
            this.createForm.logo = event.target.files[0]
          }else{
            document.getElementById("logo").value = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
        }
      }
    },
  }
</script>

<style>
.ckeditor {
      height: 400px;
      width: 100%;
  }
  .ck-content {
      height:350px;
  }
  .margin-bottom-20{
    margin-bottom:30px ;
  }
  .divInfo {
        border-radius: 10px
    }
</style>
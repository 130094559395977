<template>
  <div id="comments-create">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <comments-form-create></comments-form-create>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import CommentsFormCreate from './components/CommentsFormCreate'

  export default{
    name: 'CommentsCreate',
    components: {CommentsFormCreate},
    data() {
      return {
      }
    },
  }
</script>